import Row from '../components/common/Row';
import Column from '../components/common/Column';
import Preview from '../components/Preview';
import MessageCreationForm from '../components/MessageCreation/MessageCreationForm';
import { useContext } from 'react';
import { CustomActivityContext } from '../contexts/customActivity.context';
import axios from 'axios';
import { TAKO_API_BASE_URL, TAKO_API_KEY } from '../config';
import { useEffect } from 'react';
import { TemplateContext } from '../contexts/templates.context';
// import { tempActivityDefaultValues } from '../contexts/tempInitConfig';
import theme from '../styles/theme';

const JourneyBuilder = () => {
  const { activityKey, journeyId, showAlertToClose } = useContext(
    CustomActivityContext,
  );

  const { setActivityDefaultValues, setSavedActivityData } =
    useContext(TemplateContext);

  useEffect(() => {
    const fetchSavedFormData = async () => {
      if (!activityKey || !journeyId) {
        return;
      }
      try {
        const response = await axios.get(
          `${TAKO_API_BASE_URL}/sfmc-jb-custom-activity/saved-activity?journeyId=${journeyId}&activityKey=${activityKey}`,
          {
            headers: {
              'x-api-key': TAKO_API_KEY,
            },
          },
        );

        console.log({ fetchSavedFormData: response.data });
        if (response.data) {
          const {
            activityDefaultValues,
            // eventDefinitionKey: savedEventDefinitionKey,
          } = response.data;

          setSavedActivityData(response.data);
          // console.log(
          //   'activityDefaultValues:',
          //   JSON.parse(activityDefaultValues),
          // );
          // console.log({
          //   currentDE: eventDefinitionKey,
          //   savedDE: savedEventDefinitionKey,
          // });

          const activityDefaultValuesParsed = JSON.parse(activityDefaultValues);
          if (!activityDefaultValuesParsed.bulkSendId) {
            activityDefaultValuesParsed.bulkSendId = '';
          }

          setActivityDefaultValues(activityDefaultValuesParsed);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // if (activityKey && journeyId) {
    // }
    fetchSavedFormData();
    // if (
    //   window.location.hostname === 'localhost' ||
    //   window.location.hostname === '127.0.0.1'
    // ) {
    //   setActivityDefaultValues(tempActivityDefaultValues);
    // }
  }, [activityKey, journeyId]);

  return (
    <div
      style={{
        width: '100%',
        maxHeight: '100vh',
        height: '100vh',
        color: theme.colors.grey[500],
        overflow: showAlertToClose ? 'hidden' : '',
      }}
      id="app"
    >
      <Row style={{ height: '100vh', minHeight: '560px' }}>
        <Column
          id="template-config"
          style={{
            height: '100vh',
            backgroundColor: '#EFF0F5',
            padding: '50px',
            overflow: 'auto',
            boxSizing: 'border-box',
            position: 'relative',
            flex: 6,
          }}
        >
          <MessageCreationForm />
        </Column>
        <Column $grow style={{ height: '100%', flex: 4 }} $alignCenter $center>
          <Preview />
        </Column>
      </Row>
    </div>
  );
};

export default JourneyBuilder;
