import {
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import Row from '../common/Row';
import Button from '../common/Button';
import Switch from 'react-switch';
import '../../styles/journey.css';
import Loader from '../Loader';
import eventBus from '../../utils/eventbus';
import Modal from 'react-modal';
import TemplateModalContent from '../TemplateModalContent';
import { FiArrowRight } from 'react-icons/fi';
import { FaList } from 'react-icons/fa';
import connection from '../../utils/postmonger';
import { TAKO_API_BASE_URL, TAKO_API_KEY } from '../../config';
import {
  CustomVariablesOption,
  TemplateCategoryOption,
  TemplateOption,
} from '../../types/jbForm.types';
import { SendMsgClientRequestBody } from '../../types/sendMessageRequest.types';
import { TemplateContext } from '../../contexts/templates.context';
import { CustomActivityContext } from '../../contexts/customActivity.context';
import { Overlay } from '../common/Overlay';
import { CustomActivityConfig } from '../../types/CustomActivityConfig.types';
import { tempInitConfig } from '../../contexts/tempInitConfig';
import axios from 'axios';
import { MessageSourceEnum } from '../../enums/sendMessageRequest.enum';
import theme from '../../styles/theme';
import MessageTypeSelect from '../template_config/MessageTypeSelect';
import { TemplateCategoryEnum } from '../../enums/templateForm.enums';
import { ToolTipContainer, Tooltip } from '../tooltip/tooltip.styles';
import { VscTriangleUp } from 'react-icons/vsc';
import { TopButton } from '../TopButton';
import help from '../../assets/help.svg';
import DropdownSelect from '../form/DropdownSelect';
import StyledInput from '../form/StyledInput';
import { useIsOverflow } from '../../hooks/isOverflow';
import { FormSectionWrap, WarningContainer } from '../form/FormSection';
import { MessageCreationPage, SubText } from './MessageCreation.style';
import SwitchField from '../SwitchFields/SwitchField';
import {
  requiredSwitchFieldsConfig,
  customKeysSwitchFieldsConfig,
  SwitchFieldKeyEnum,
  HandleSwitchEnum,
} from '../SwitchFields/switchFieldsConfig';
import { ulid } from 'ulid';

const MessageCreationForm = () => {
  const {
    initData,
    allTemplatesData,
    selectedCategoryTemplatesData,
    changeTemplateListTemplates,
    selectedTemplateCategory,
    setSelectedTemplateCategory,
    templatesList,
    setTemplatesList,
    selectedTemplate,
    setSelectedTemplate,
    currentTemplateData,
    loading,
    templatesByCategories,
    smsTitle,
    setSmsTitle,
    messageHasImage,
    allowFallback,
    setAllowFallback,
    overrideVariables,
    setOverrideVariables,
    customVariableOptions,
    setCustomVariableOptions,
    activityDefaultValues,
    savedActivityData,
    setCurrentTemplateData,
    requireCustomField,
  } = useContext(TemplateContext);

  const {
    activityConfig,
    setActivityConfig,
    journeyId,
    activityKey,
    eventDefinitionKey,
    journeyVersion,
  } = useContext(CustomActivityContext);

  const [showModal, setShowModal] = useState(false);
  const [templateFields, setTemplateFields] = useState<string[]>([]);

  const [selectedCustomVariables, setSelectedCustomVariables] = useState<
    number[]
  >([]);

  const [switchFieldData, setSwitchFieldData] = useState<
    Record<
      SwitchFieldKeyEnum,
      {
        mandatory: string;
        override: boolean;
      }
    >
  >({
    [SwitchFieldKeyEnum.phoneNumber]: { mandatory: '', override: false },
    [SwitchFieldKeyEnum.messageId]: { mandatory: '', override: false },
    [SwitchFieldKeyEnum.customKey1]: { mandatory: '', override: false },
    [SwitchFieldKeyEnum.customKey2]: { mandatory: '', override: false },
    [SwitchFieldKeyEnum.customKey3]: { mandatory: '', override: false },
  });

  const handleSetSwitchField = (
    fieldName: SwitchFieldKeyEnum,
    subField: HandleSwitchEnum,
    value: string | boolean,
  ) => {
    setSwitchFieldData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        [subField]: value,
      },
    }));
  };

  const [sendMessageRequestBody, setSendMessageRequestBody] =
    useState<SendMsgClientRequestBody>({} as SendMsgClientRequestBody);
  const [
    templateFieldValuesForRequestBody,
    setTemplateFieldValuesForRequestBody,
  ] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (!currentTemplateData) return;
    let fields = [] as string[];
    if (currentTemplateData['templateFields']) {
      fields = currentTemplateData['templateFields'];
    }

    setTemplateFields(fields);
    setSelectedTemplate({
      value: currentTemplateData['templateCode'],
      label: currentTemplateData['templateName'],
    });

    if (
      fields.length > 0 &&
      fields.some(
        (templateField) =>
          !customVariableOptions.find(
            (customVar) => customVar.label === templateField,
          ),
      )
    ) {
      setOverrideVariables(true);
    }

    if (
      currentTemplateData &&
      currentTemplateData.templateFields &&
      currentTemplateData.templateFields.length > 0
    ) {
      const customVarIndexMatchArr = currentTemplateData.templateFields.map(
        (value) => {
          const matchIndex = customVariableOptions.findIndex(
            (obj) => obj.label === value,
          );
          return matchIndex >= 0 ? matchIndex : 0;
        },
      );
      setSelectedCustomVariables(customVarIndexMatchArr);
    }
  }, [currentTemplateData]);

  useEffect(() => {
    if (!loading) return;
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // console.log('Setting up fake custom variables');
      setCustomVariableOptions([
        // {
        //   value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var5',
        //   label: 'phoneNumber',
        // },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var1',
          label: 'eventName',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var2',
          label: 'cancelReason',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var3',
          label: 'customerFullName',
        },
        {
          value: 'Event.DEAudience-d7a55f34-0d15-149e-b05d-0804fd67d709.var4',
          label: 'reservationNo',
        },
      ]);
    }
    eventBus.on('receivedSchema', (data: CustomVariablesOption[]) => {
      // console.log('Received schema...', data);
      setCustomVariableOptions(data);
    });
    eventBus.on('closeTemplateModal', () => {
      setShowModal(false);
    });

    connection.trigger('ready');
    connection.trigger('requestEndpoints');
    connection.trigger('requestInteractionDefaults');
    connection.trigger('requestSchema');
  }, []);

  useEffect(() => {
    if (
      eventDefinitionKey &&
      savedActivityData &&
      activityDefaultValues &&
      customVariableOptions &&
      eventDefinitionKey === savedActivityData.eventDefinitionKey &&
      currentTemplateData?.templateCode === activityDefaultValues.templateCode
    ) {
      const templateFields =
        currentTemplateData && currentTemplateData.templateFields;
      const savedCustomVariables = activityDefaultValues.templateFields;
      if (templateFields) {
        const customVarsIndexArr = templateFields.map((field) => {
          const valueToMatch = savedCustomVariables[field];
          const matchedIndex = customVariableOptions.findIndex((obj) => {
            return `{{${obj.value}}}` === valueToMatch;
          });
          return matchedIndex !== -1 ? matchedIndex : 0;
        });
        setSelectedCustomVariables(customVarsIndexArr);
      }
    } else {
      const customVarIndexMatchArr = templateFields.map((value) => {
        const matchIndex = customVariableOptions.findIndex(
          (obj) => obj.label === value,
        );
        return matchIndex >= 0 ? matchIndex : 0;
      });
      setSelectedCustomVariables(customVarIndexMatchArr);
    }
  }, [
    currentTemplateData,
    eventDefinitionKey,
    activityDefaultValues,
    savedActivityData,
    customVariableOptions,
    templateFields,
  ]);

  useEffect(() => {
    const requestBody = {} as SendMsgClientRequestBody;
    if (selectedTemplateCategory && currentTemplateData) {
      if (
        switchFieldData.messageId.override &&
        !!switchFieldData.messageId.mandatory
      ) {
        requestBody.messageId = `{{${switchFieldData.messageId.mandatory}}}`;
      }
      requestBody.profileKey = initData.profileKey;
      requestBody.brandCode = initData.brandCode;
      requestBody.reservedTime = '00000000000000';
      requestBody.templateCode =
        currentTemplateData && currentTemplateData.templateCode;
      requestBody.receiverNum =
        switchFieldData.phoneNumber.mandatory === ''
          ? `{{Event.${eventDefinitionKey}.phoneNumber}}`
          : `{{${switchFieldData.phoneNumber.mandatory}}}`;
      requestBody.templateFields = templateFieldValuesForRequestBody;
      if (selectedTemplateCategory.value === TemplateCategoryEnum.SMS) {
        requestBody.smsOnly = 'Y';
      }
      requestBody.setFallback = allowFallback ? 'Y' : 'N';
      if (allowFallback) {
        requestBody.smsOnly = 'N';
      }

      if (
        switchFieldData.customKey1.override &&
        !!switchFieldData.customKey1.mandatory
      ) {
        requestBody.customKey1 = `{{${switchFieldData.customKey1.mandatory}}}`;
      }
      if (
        switchFieldData.customKey2.override &&
        !!switchFieldData.customKey2.mandatory
      ) {
        requestBody.customKey2 = `{{${switchFieldData.customKey2.mandatory}}}`;
      }
      if (
        switchFieldData.customKey3.override &&
        !!switchFieldData.customKey3.mandatory
      ) {
        requestBody.customKey3 = `{{${switchFieldData.customKey3.mandatory}}}`;
      }

      requestBody.messageSource = MessageSourceEnum.SFMC_JB;

      requestBody.bulkSendId = ulid();
      // console.log({ currentTemplateData, requestBody });

      setSendMessageRequestBody(requestBody);
    }
  }, [
    switchFieldData,
    customVariableOptions,
    currentTemplateData,
    templateFieldValuesForRequestBody,
    selectedCustomVariables,
    smsTitle,
    overrideVariables,
    allowFallback,
    messageHasImage,
  ]);

  useEffect(() => {
    if (
      currentTemplateData &&
      currentTemplateData.templateFields &&
      currentTemplateData.templateFields.length ===
        selectedCustomVariables.length
    ) {
      const fields: { [key: string]: string } =
        currentTemplateData.templateFields.reduce((acc, field, index) => {
          acc[field] =
            overrideVariables && selectedCustomVariables.length > 0
              ? `{{${
                  customVariableOptions[selectedCustomVariables[index]]['value']
                }}}`
              : `{{Event.${eventDefinitionKey}.${field}}}`;
          return acc;
        }, {} as { [key: string]: string });
      setTemplateFieldValuesForRequestBody(fields);
    }
  }, [
    currentTemplateData,
    templateFields,
    selectedCustomVariables,
    overrideVariables,
  ]);

  useEffect(
    function () {
      connection.off('clickedNext');
      connection.on('clickedNext', save);
    },
    [
      customVariableOptions,
      overrideVariables,
      selectedCustomVariables,
      selectedTemplate,
      smsTitle,
      allowFallback,
      initData,
      activityConfig,
      sendMessageRequestBody,
    ],
  );

  const onCategoryChange = (category: TemplateCategoryOption) => {
    setAllowFallback(false);
    setOverrideVariables(false);
    // setOverrideMandatoryFields(false);
    setSmsTitle(null);
    setSelectedTemplate(null);
    setTemplatesList(templatesByCategories[category.label]);
    setSelectedTemplateCategory(category);
    setCurrentTemplateData(null);
    setSendMessageRequestBody({} as SendMsgClientRequestBody);
    changeTemplateListTemplates(allTemplatesData, category);
  };

  const save = async function () {
    if (customVariableOptions.length === 0) {
      return;
    }

    let updatedActivityConfig = activityConfig || ({} as CustomActivityConfig);

    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      updatedActivityConfig = tempInitConfig;
    }

    let bulkSendId = sendMessageRequestBody.bulkSendId;

    if (!bulkSendId) {
      bulkSendId = ulid();

      setSendMessageRequestBody((prev) => ({
        ...prev,
        bulkSendId,
      }));

      // console.log({ bulkSendId, sendMessageRequestBody });
    }

    const updatedMessageRequestBody = {
      ...sendMessageRequestBody,
      bulkSendId,
    };

    updatedActivityConfig.arguments.execute.body = JSON.stringify(
      updatedMessageRequestBody,
    );
    if (!updatedActivityConfig.arguments.execute.url?.includes('/bulk-send')) {
      updatedActivityConfig.arguments.execute.url = `${TAKO_API_BASE_URL}/bulk-send`;
    }
    updatedActivityConfig.metaData.isConfigured = true;

    const activityCurrentState = {
      ...updatedMessageRequestBody,
      allowFallback,
      overrideVariables,
    };

    const saveActivityDefaultValues = {
      journeyId,
      activityKey,
      activityDefaultValues: JSON.stringify(activityCurrentState),
      eventDefinitionKey,
      journeyVersion,
    };

    setActivityConfig(updatedActivityConfig);

    if (journeyId && activityKey) {
      try {
        await axios.post(
          `${TAKO_API_BASE_URL}/sfmc-jb-custom-activity/saved-activity`,
          saveActivityDefaultValues,
          {
            headers: {
              'x-api-key': TAKO_API_KEY,
            },
          },
        );
      } catch (error) {
        console.log({ error });
      }
    }

    connection.trigger('updateActivity', updatedActivityConfig);
  };

  const onTemplateChange = (template: TemplateOption | null) => {
    if (template) {
      setSmsTitle(null);
      setSelectedTemplate(template);
      setOverrideVariables(false);
    }
  };

  const changeCustomVariable = (
    variable: CustomVariablesOption,
    index: number,
  ) => {
    const newIndex = customVariableOptions.indexOf(variable);
    setSelectedCustomVariables((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = newIndex;
      return newIndexes;
    });
  };

  const messageTypeSelectRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isOverflow = useIsOverflow({ ref: scrollRef });

  return (
    <>
      <MessageCreationPage ref={scrollRef}>
        {loading && (
          <Overlay>
            <Loader />
          </Overlay>
        )}

        <div ref={messageTypeSelectRef}>
          <MessageTypeSelect onCategoryChange={onCategoryChange} />
        </div>
        <div className="container">
          <FormSectionWrap>
            <div className="label-wrap">
              <div className="label-text">Select template</div>
              <Button
                $bold
                padding="5px 15px"
                size="12px"
                color={theme.colors.primary[200]}
                onClick={() => setShowModal(true)}
                style={{ gap: '10px' }}
              >
                <FaList className="icon" />
                <div>TEMPLATE LIST</div>
              </Button>
            </div>

            <DropdownSelect
              value={selectedTemplate}
              options={templatesList}
              placeholder="Select template"
              onChange={onTemplateChange}
              isSearch={true}
            />
          </FormSectionWrap>

          {selectedTemplateCategory &&
            selectedTemplateCategory.label !== TemplateCategoryEnum.SMS &&
            currentTemplateData?.fallback && (
              <FormSectionWrap>
                <div className="label-wrap">
                  <div className="label-text-wrap">
                    <div className="label-text">Enable Fallback</div>
                    <Tooltip>
                      <img src={help} />
                      <ToolTipContainer>
                        A fallback message is a backup message (LMS or MMS) sent
                        when the Alimtalk or Friendtalk fails to send through
                        Kakao.
                      </ToolTipContainer>
                    </Tooltip>
                  </div>
                  <Switch
                    checked={allowFallback}
                    onChange={setAllowFallback}
                    onColor={theme.colors.primary[200]}
                    onHandleColor="#fff"
                    activeBoxShadow="0 0 0px 0px #FFF"
                    width={34}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={16}
                    height={20}
                    offColor="#D9D9D9"
                  />
                </div>
              </FormSectionWrap>
            )}

          {selectedTemplateCategory &&
            selectedTemplateCategory.label === TemplateCategoryEnum.SMS &&
            currentTemplateData?.senderNum && (
              <FormSectionWrap>
                <div className="label-wrap">
                  <div className="label-text">Title</div>
                </div>
                <StyledInput
                  value={smsTitle ? smsTitle : ''}
                  placeholder={
                    selectedTemplateCategory &&
                    selectedTemplateCategory.label === TemplateCategoryEnum.SMS
                      ? 'Enter a title for the message'
                      : 'Enter title for the fallback message'
                  }
                  onChange={(e) => {
                    setSmsTitle(e.target.value);
                  }}
                />
              </FormSectionWrap>
            )}

          {templateFields.length != 0 && (
            <FormSectionWrap>
              <div className="label-wrap">
                <div className="label-text">Use custom variables</div>
                <Row>
                  <SubText style={{ marginRight: '10px' }}>
                    enable custom variables for template fields
                  </SubText>
                  <Switch
                    checked={overrideVariables}
                    onChange={setOverrideVariables}
                    onColor={theme.colors.primary[200]}
                    onHandleColor="#fff"
                    activeBoxShadow="0 0 0px 0px #FFF"
                    width={34}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={16}
                    className={overrideVariables ? 'switch--active' : ''}
                    height={20}
                    offColor="#D9D9D9"
                    disabled={requireCustomField}
                  />
                </Row>
              </div>

              {overrideVariables &&
                templateFields &&
                customVariableOptions &&
                templateFields.map((field, index) => (
                  <Row key={'variable_' + index} $spaceBetween>
                    <StyledInput
                      value={'#' + field}
                      width="45%"
                      readOnly={true}
                    />
                    <FiArrowRight />
                    <DropdownSelect
                      value={
                        customVariableOptions[selectedCustomVariables[index]]
                      }
                      onChange={(v) => {
                        v && changeCustomVariable(v, index);
                      }}
                      options={customVariableOptions}
                      width="45%"
                    />
                  </Row>
                ))}
            </FormSectionWrap>
          )}
          <FormSectionWrap>
            {requiredSwitchFieldsConfig.map(
              ({ key, label, warning, error }) => (
                <SwitchField
                  key={key}
                  label={label}
                  fieldName={key}
                  fieldValue={switchFieldData[key].mandatory}
                  options={customVariableOptions}
                  override={switchFieldData[key].override}
                  handleSetSwitchField={handleSetSwitchField}
                  warningMessage={warning}
                  errorMessage={error}
                />
              ),
            )}
          </FormSectionWrap>
          <FormSectionWrap>
            {customKeysSwitchFieldsConfig.warning && (
              <WarningContainer>
                {customKeysSwitchFieldsConfig.warning}
              </WarningContainer>
            )}
            {customKeysSwitchFieldsConfig.switchFields.map(
              ({ key, label, warning, error }) => (
                <SwitchField
                  key={key}
                  label={label}
                  fieldName={key}
                  fieldValue={switchFieldData[key].mandatory}
                  options={customVariableOptions}
                  override={switchFieldData[key].override}
                  handleSetSwitchField={handleSetSwitchField}
                  warningMessage={warning}
                  errorMessage={error}
                />
              ),
            )}
          </FormSectionWrap>
        </div>
      </MessageCreationPage>
      <TopButton
        id="top-button"
        onClick={() => {
          if (messageTypeSelectRef.current) {
            messageTypeSelectRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        className={isOverflow ? '' : 'is-overflow'}
      >
        <div>
          <VscTriangleUp />
          TOP
        </div>
      </TopButton>
      <Modal
        style={{
          content: {
            zIndex: 45,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
          },
        }}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Example Modal"
      >
        <TemplateModalContent templates={selectedCategoryTemplatesData} />
      </Modal>
    </>
  );
};

export default MessageCreationForm;
