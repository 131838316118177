import Switch from 'react-switch';
import { ErrorMessage, SubText, WarningContainer } from '../form/FormSection';
import theme from '../../styles/theme';
import { SingleValue } from 'react-select';
import { CustomVariablesOption } from '../../types/jbForm.types';
import { FiArrowRight } from 'react-icons/fi';
import Row from '../common/Row';
import DropdownSelect from '../form/DropdownSelect';
import StyledInput from '../form/StyledInput';
import { HandleSwitchEnum, SwitchFieldKeyEnum } from './switchFieldsConfig';
import { useEffect } from 'react';

type SwithFieldProps = {
  fieldName: SwitchFieldKeyEnum;
  label: string;
  options: CustomVariablesOption[];
  fieldValue: string;
  override: boolean;
  handleSetSwitchField: (
    fieldName: SwitchFieldKeyEnum,
    subField: HandleSwitchEnum,
    value: string | boolean,
  ) => void;
  warningMessage?: string | JSX.Element;
  errorMessage?: string;
};

const SwitchField = ({
  fieldName,
  label,
  options,
  fieldValue,
  override,
  handleSetSwitchField,
  warningMessage,
  errorMessage,
}: SwithFieldProps) => {
  const onChange = function (newValue: SingleValue<CustomVariablesOption>) {
    if (newValue) {
      handleSetSwitchField(
        fieldName,
        HandleSwitchEnum.mandatory,
        newValue.value,
      );
    }
  };

  const disableSwitch =
    options &&
    options.length > 0 &&
    fieldName === SwitchFieldKeyEnum.phoneNumber &&
    !options.some((field) => field.label === SwitchFieldKeyEnum.phoneNumber);

  useEffect(() => {
    if (disableSwitch) {
      handleSetSwitchField(fieldName, HandleSwitchEnum.override, true);
    }
  }, [fieldName, options]);

  return (
    <>
      <div className="label-wrap">
        <div className="label-text label-text--primary">{label}</div>
        <div className="label-text-wrap">
          <SubText>
            Enable to set{' '}
            <span
              style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}
            >
              {fieldName}
            </span>{' '}
            with custom variables
          </SubText>
          <Switch
            checked={override}
            onChange={(value) => {
              handleSetSwitchField(fieldName, HandleSwitchEnum.override, value);
              if (!value) {
                handleSetSwitchField(fieldName, HandleSwitchEnum.mandatory, '');
              }
            }}
            onColor={theme.colors.primary[200]}
            onHandleColor="#fff"
            activeBoxShadow="0 0 0px 0px #FFF"
            width={34}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            className={override ? 'switch--active' : ''}
            height={20}
            offColor="#D9D9D9"
            disabled={disableSwitch}
          />
        </div>
      </div>
      {override && (
        <>
          {warningMessage && (
            <WarningContainer>{warningMessage}</WarningContainer>
          )}

          <span>
            <Row $spaceBetween>
              <StyledInput value={fieldName} width="45%" readOnly={true} />
              <FiArrowRight />
              <DropdownSelect
                defaultValue={options.find(
                  (field) => field.label === fieldName,
                )}
                onChange={onChange}
                options={options}
                width="45%"
              />
            </Row>

            {options &&
            !options.some((field) => field.label === fieldName) &&
            fieldValue === ''
              ? errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
              : ''}
          </span>
        </>
      )}
    </>
  );
};

export default SwitchField;
